const routes = [
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    children: [
      { path: '', component: () => import('../views/Inicio.vue') },
      { path: 'empresa', component: () => import('../views/Empresa.vue') },
      { path: 'contacto', component: () => import('../views/Contacto.vue') },
      { path: 'politica-cookies', component: () => import('../views/Cookies.vue') },
      { path: 'politica-datos', component: () => import('../views/PoliticaDatos.vue') },
      { path: 'aviso-legal', component: () => import('../views/AvisoLegal.vue') },
      { path: 'condiciones-compra', component: () => import('../views/CondicionesCompra.vue') },
      { path: 'horarios', beforeEnter() {location.href = 'https://regular.autobusing.com/info/index?empresa=anesa'} },
      { path: 'venta', beforeEnter() {location.href = 'https://regular.autobusing.com/venta/index?empresa=anesa'} },
      { path: 'anular', beforeEnter() {location.href = 'https://regular.autobusing.com/anulacion/index?empresa=anesa'} },
      { path: 'cambiar', beforeEnter() {location.href = 'https://regular.autobusing.com/cambio/index?empresa=anesa'} },
      { path: 'cierre_vta', beforeEnter() {location.href = 'https://regular.autobusing.com/cierre_vuelta/index?empresa=anesa'} },
      { path: 'duplicado', beforeEnter() {location.href = 'https://regular.autobusing.com/reimpresion/index?empresa=anesa'} },
      { path: '*', component: () => import('../views/Error404.vue') },
    ]
  },
]

export default routes
