import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import app from './App'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default function () {
  const Store = new Vuex.Store({
    modules: {
      app
    },
    plugins: [vuexLocal.plugin]
  })
  return Store
}
