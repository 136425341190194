import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior: function (to) {
    return { x: 0, y: 0 }
  },
  routes,
  mode: 'history'
})

export default router
