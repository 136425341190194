import axios from 'axios'

export function send_mail (context,data) {
  return axios(
    {
      method: 'POST',
      url:  window.location.origin + '/api/send_mail',
      data: data
    }
  )
}




