import Vue from 'vue'
import Anesa from './Anesa.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import axios from 'axios'
import vmodal from 'vue-js-modal'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(Anesa)
}).$mount('#anesa')

Vue.use(VueMeta)
Vue.use(vmodal, { dialog: true })
